<template>
  <KeyList 
    :title="FIELDS.COUNTRY" 
    placeholder="Country" 
    :options="options"
    :disabled="isSaving" 
    :defaultValue="country" 
    v-on:value="setValue" />
  
</template>

<script>
import { mapGetters } from 'vuex'
import KeyList from '@/components/input/KeyList.vue';
import Address from '@/domain/model/address/Address.js';
import ContentUtils from '@/utils/ContentUtils.js';
import Catelog from '@/domain/session/CanadianEnglish.js';

export default  {
  name: 'country',
  props: {
    isSaving: { type: Boolean, default: false },
    defaultValue: { type: String, default: "" },
  },
  components: {
    KeyList,
  },
  data() {
    return {
      catelog: Catelog.KEYS,
      FIELDS: Address.FIELDS,
      options: ContentUtils.COUNTRYS(),
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    country: function() {
      return this.defaultValue;
    },
  },
  watch: {
    //
  },
  mounted: function () {
  },
  methods: {
    setValue: function (kvp) {
      this.$emit('setValue', {'key': kvp.key, 'value': kvp.value})
    },
  },
}
</script>