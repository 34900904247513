<template>
<Row>
  <div v-if="left" :class="leftPadding"></div>
  <div :class="middle">
    <Box>
      
      <TitleRow v-if="showTitle" 
        :label="catelog.get(title)" 
        :fontSize="fontSize" />
      
      <KeyValue
        :title="FIELDS.STREET"
        placeholder="No. and Street"
        :defaultValue="street"
        :disabled="isSaving"
        v-on:value="setValue" />
      
      <KeyValue
        :title="FIELDS.CITY"
        placeholder="City"
        :defaultValue="city"
        :disabled="isSaving"
        v-on:value="setValue" />

      <div class="row">
        <div class="rc-box-col-8">
          <div class="container">
            <JurisdictionRow
              :disabled="isSaving"
              :defaultValue="jurisdiction"
              v-on:setValue="setValue"/>
          </div>
        </div>
        <div class="rc-box-col-7">
          <div class="container">
            <CountryRow
              :disabled="isSaving"
              :defaultValue="country"
              v-on:setValue="setValue"
            />
          </div>
        </div>
      </div>
      
      <KeyValue
        :title="FIELDS.POSTAL"
        placeholder="Postal Code"
        :defaultValue="postal"
        :disabled="isSaving"
        v-on:value="setValue" />

    </Box>
  </div>
  <div v-if="right" :class="rightPadding"></div>
</Row>
</template>

<script>
import { mapGetters } from 'vuex'
import Address from '@/domain/model/address/Address.js';
import Catelog from '@/domain/session/CanadianEnglish.js';
import KeyValue from '@/components/input/KeyValue.vue';
import TitleRow   from "@/components/row/TitleRow";
import CountryRow from './Country.vue';
import JurisdictionRow from './Jurisdiction.vue';

import Box from '@/portals/shared/library/box/Box.vue';
import Row from '@/portals/shared/library/box/Row.vue';
//import Column from '@/portals/shared/library/box/Column.vue';

export default  {
  name: 'address-row',
  components: {
    KeyValue,
    CountryRow,
    JurisdictionRow,
    TitleRow,
    Box, Row, 
  },
  props: {
    isSaving: {type: Boolean, default: false},
    addressData: {type: Object, default: () => {} },
    showTitle: {type: Boolean, default: true},
    left:     { type: Number, default : 0 },
    right:    { type: Number, default : 0 },
    title:    { type: String, default : 'ADDRESS_TITLE' },
    fontSize: { type: String, default: "" },
  },
  data() {
    return {
      catelogKeys: Catelog.KEYS,
      catelog: new Catelog(),
      FIELDS: Address.FIELDS,
    }
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'domain',
                ]),
    data: function() {
      return this.addressData;
    },
    address: function() {
      return new Address(this.domain, this.addressData);
    },
    
    street: function() {
      if (this.data) {
        return this.address.street();
      }
      return "";
    },
    city: function() {
      if (this.data) {
        return this.address.city();
      }
      return "";
    },
    jurisdiction: function() {
      if (this.data) {
        return this.address.province();
      }
      return "";
    },
    country: function() {
      if (this.data) {
        return this.address.country();
      }
      return "";
    },
    postal: function() {
      if (this.data) {
        return this.address.postal();
      }
      return "";
    },
    leftPadding: function() {
      if (this.left > 0) {
        return "rc-box-col-" + this.left;
      }
      return "";
    },
    rightPadding: function() {
      if (this.right > 0) {
        return "rc-box-col-" + this.right;
      }
      return "";
    },
    middle: function() {
      var value = (15 - this.left - this.right);
      return "rc-box-col-" + value;
    },
  },
  methods: {
    setValue: function(kvp) {
      if (kvp) {
        this.$emit('value', {'key': kvp.key, 'value': kvp.value});
      }
    },
    
  }
}

</script>